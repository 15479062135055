
export default {
  // ten komponent jest dla zachowania struktury z BE, ktory wysyla niepotrzebne puste zagniezdzenia
  name: 'HomePageSlot',

  props: {
    api: {
      type: Object,
      required: true,
    },
  },
}
